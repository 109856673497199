import React from 'react';
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import diamondShape from "../../assets/images/diamond-shape.svg";
import code from "../../assets/images/code.svg";
import bannerDots from "../../assets/images/banner-dots-1.svg";
import triangle from "../../assets/images/triangle.svg";

import Logo from '../../assets/images/logos/logo.svg';
import {Stack} from "@mui/material";
import SocialMediaButton from "../SocialMediaButton/socialMediaButton";

const Footer = (props) => {
    const {footerLogo, footerClass, footerCTA} = props;
    return (
        <footer className={footerClass ? footerClass : 'ras-footer ras-footer-1'}>
            <div className="container">
                {
                    footerCTA ?
                        <div className="row">
                            <div className="col-lg-12">
                                <Fade bottom>
                                    <div className="ras-calltoaction">
                                        <h2>If you have any project or task for us <br/> <b> Please contact us</b></h2>
                                        <Link to="/contact" className="btn btn-primary btn-large">Contact Us!</Link>
                                    </div>
                                </Fade>
                            </div>
                        </div> : ''
                }

                <Fade left cascade>
                    <div className="row">
                        <div className="col-lg-5 col-md-12 ras-widget-about">
                            <div className="ras-about-logo">
                                <Link to="/"><img src={footerLogo ? footerLogo : Logo} alt="Footer Logo"/></Link>
                            </div>

                            <p className="ras-about-desc">Great Solutions for Your Business </p>

                            <div className="ras-about-address">
                                <p className="mb-0">4868 SW 72nd Ave, Miami, FL 33155</p>
                                <p>Phone: <a href="tel:7866563158">(786) 656-3158</a>
                                    <span className="email">Email: <a
                                        href="mailto:info@idevops7.com">info@idevops7.com</a></span></p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-12 ras-widget-links" style={{
                            marginTop: "25px"
                        }}>
                            <h3 className="ras-widget-title">Quick Links</h3>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/service">Services</Link></li>
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/contact">Contact us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 ras-widget-subscribe" style={{
                            marginTop: "25px"
                        }}>
                            <h3 className="ras-widget-title">Social Media</h3>
                            <p>Follow us on our social media channels to stay updated.</p>
                            <Stack direction="row" spacing={2}>
                                <Link to={{pathname: "https://x.com/home"}} target="_blank"><SocialMediaButton
                                    icon="x"/></Link>
                                <Link to={{pathname: "https://www.facebook.com/"}} target="_blank"><SocialMediaButton
                                    icon="facebook"/></Link>
                                <Link to={{pathname: "https://www.instagram.com/"}} target="_blank"><SocialMediaButton
                                    icon="instagram"/></Link>
                                <Link to={{pathname: "https://www.youtube.com/"}} target="_blank"><SocialMediaButton
                                    icon="youtube"/></Link>
                            </Stack>
                        </div>
                    </div>
                </Fade>

                <div className="ras-copyright mt-60">
                    <div className="row ras-copyright-wrapper relative">
                        <Fade bottom>
                            <div className="col-md-6 text-lg-center">
                                <p className="copyright-text mb-0">© 2025 All Right Reserved, <Link
                                    to="/">iDevOps7</Link></p>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
            {/*<div className="ras-footer-shapes">*/}
            {/*    <img src={diamondShape} alt="Diamond Shape" className="diamond-shape"/>*/}
            {/*    <img src={triangle} alt="Traingle Shape" className="triangle-shape"/>*/}
            {/*    <img src={code} alt="Curly shape" className="curly-shape"/>*/}
            {/*    <img src={bannerDots} alt="Dot Shape" className="square-dots-1"/>*/}
            {/*</div>*/}
        </footer>
    );
}

export default Footer;